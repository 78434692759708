/* eslint-disable import/named */
import { Preferences } from '@capacitor/preferences';
const usePreferences = () => {
    const config = (options) => Preferences.configure(options);
    const get = (options) => Preferences.get(options);
    const remove = (options) => Preferences.remove(options);
    const clear = () => Preferences.clear();
    const set = (options) => Preferences.set(options);
    const getKeys = () => Preferences.keys();
    const migrate = () => Preferences.migrate();
    const removeOld = () => Preferences.removeOld();
    return {
        get,
        Preferences,
        config,
        remove,
        clear,
        set,
        getKeys,
        migrate,
        removeOld
    };
};
export default usePreferences;
